import React from "react";
import { Link } from "react-router-dom";
import './frameNew.css'

const PageNotFound = () => {
    return (
        <div className="d-flex align-items-center justify-content-center vh-100 errorPageMainClassOutside">
            <div className="text-center errorPageMainClass">
                <p className="display-1 fw-bold errorHead">404</p>
                <p className="fs-3 errorOopsPara"> <span className="text-danger">Opps! </span> Sorry the page you are looking could not be found!</p>
             
                {/* <Link to="/userprofile" className="btn btn-primary addBtn goBackButton">Go Back to Home</Link> */}
            </div>
        </div>
    )
}

export default PageNotFound